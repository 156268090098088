import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';

import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import { getUserContext } from '~/selectors/user';
import { maybeFetchUser } from '~/actions/UserActions';
import useNavData from '~/components/navV2/useNavData';
import useScrollPosition from '../../hooks/useScrollPosition';

import { MultiAnnouncementBanner } from './MultiAnnouncementBanner/MultiAnnouncementBanner';
import { ChecklistDrawer } from './ChecklistDrawer/ChecklistDrawer';
import { DesktopNav } from './DesktopNav/DesktopNav';
import { MobileNav } from './MobileNav/MobileNav';

import styles from './babyNav.module.less';
import '../navV2/navV2.less';

const BabyNav = (): JSX.Element => {
  const dispatch = useDispatch();
  const userContext = useSelector(getUserContext);
  const userContextExists = userContext && !_isEmpty(userContext);

  const { direction } = useScrollPosition();
  const defaultScrollPos = (typeof window !== 'undefined' && window?.scrollY) || 0;

  // On scroll down, collapse top banner and primary nav while keeping secondary nav expanded.
  // On scroll up, expand primary nav but keep top banner collapsed until scrolled all the way to top.
  const [hideContentOnScrollDown, setHideContentOnScrollDown] = useState(defaultScrollPos !== 0);
  const [hideContentUntilScrolledToTop, setHideContentUntilScrolledToTop] = useState(
    defaultScrollPos !== 0
  );
  const currentHideContentOnScrollDown = direction === 'down' && defaultScrollPos > 0;
  const currentHideContentUntilScrolledToTop =
    (direction === 'down' || direction === 'up') && defaultScrollPos > 0;

  useEffect(() => {
    const debouncedUpdateOnScroll = _debounce(() => {
      // Solution to maintain current nav collapsed/expanded state when scroll gets locked,
      // e.g. opening modal would expand nav because the scroll lock hook would scroll the page up.
      const isScrollLocked =
        typeof window !== 'undefined' && window.document.body.classList.contains('zui-scroll-lock');
      if (!isScrollLocked) {
        setHideContentOnScrollDown(currentHideContentOnScrollDown);
        setHideContentUntilScrolledToTop(currentHideContentUntilScrolledToTop);
      }
    }, 100);

    debouncedUpdateOnScroll();

    return () => debouncedUpdateOnScroll.cancel();
  }, [currentHideContentOnScrollDown, currentHideContentUntilScrolledToTop]);

  const onInitNavData = useCallback(
    (passedUserContext: UserContext = {}) => {
      // Force fetch userContext if it's empty
      if (_isEmpty(passedUserContext)) dispatch(maybeFetchUser(true));
    },
    [dispatch]
  );

  const { primaryLinkId, secondaryLinkId, secondaryNavData } = useNavData(
    onInitNavData,
    userContext,
    ''
  );

  useEffect(() => {
    if (_isEmpty(userContext)) {
      dispatch(maybeFetchUser());
    }
  }, [dispatch, userContext]);

  if (!userContextExists) {
    return <Fragment />;
  }

  return (
    <nav className={styles.babyNav}>
      <MultiAnnouncementBanner
        hideContentUntilScrolledToTop={hideContentUntilScrolledToTop}
        userContext={userContext}
      />
      <DesktopNav
        hideContentOnScrollDown={hideContentOnScrollDown}
        primaryLinkId={primaryLinkId}
        secondaryLinkId={secondaryLinkId}
        secondaryNavData={secondaryNavData}
        userContext={userContext}
      />
      <MobileNav
        hideContentOnScrollDown={hideContentOnScrollDown}
        primaryLinkId={primaryLinkId}
        secondaryLinkId={secondaryLinkId}
        secondaryNavData={secondaryNavData}
        userContext={userContext}
      />
      <ChecklistDrawer activeTaxonomyNodeKey={secondaryLinkId} userContext={userContext} />
    </nav>
  );
};

export default BabyNav;
