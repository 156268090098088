import React, { Fragment, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '~/reducers/index';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import NAV_DATA, {
  PrimaryNavIdentifier,
  SecondaryNavItem,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import {
  babyPreAuthPrimaryNavData,
  babyPostAuthPrimaryNavData,
} from '~/components/BabyNav/babyNavData';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { ChevronDownIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronDown';
import { ChevronRightIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronRight';
import { ExternalLinkIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ExternalLink';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { SearchIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Search';
import { XIcon } from '@zola/zola-ui/src/components/SvgIconsV3/X';
import ZolaBabyLogo from '@zola/zola-ui/src/components/ZolaBabyLogo';

import featureFlags from '~/util/featureFlags';
import { getZolaHref } from '~/util/getZolaHref';
import { signout } from '~/actions/AuthActions';
import { showModal } from '~/actions/ModalActions';

import DownloadButton from '~/assets/images/download-button.png';

import sharedStyles from '../sharedStyles.module.less';
import styles from './mobileVerticalNav.module.less';

const APP_DOWNLOAD_HREF =
  'https://apps.apple.com/app/apple-store/id6446598574?pt=31209800&ct=zola_web_nav&mt=8';

const {
  EVENTS: { MODAL_TYPE_AUTH },
} = NAV_DATA;

type MobileVerticalNavProps = {
  isVerticalNavOpen?: boolean;
  openVerticalNav: (open: boolean) => void;
  openVerticalNavAccount: (open: boolean) => void;
  primaryLinkId?: PrimaryNavIdentifier | '';
  secondaryNavData: SecondaryNavItem[];
  toggleSearch: () => void;
  userContext?: UserContext;
};

export const MobileVerticalNav = ({
  isVerticalNavOpen,
  openVerticalNav,
  openVerticalNavAccount,
  primaryLinkId,
  secondaryNavData,
  toggleSearch,
  userContext,
}: MobileVerticalNavProps): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const isGuest = useMemo(() => !userContext?.has_baby_registry, [userContext]);
  const isShopPage = useMemo(() => primaryLinkId === PrimaryNavIdentifier.SHOP, [primaryLinkId]);

  const [openShopDropdown, setOpenShopDropdown] = useState(isShopPage);

  // Open shop dropdown by default when opening mweb vertical nav from a shop page
  useEffect(() => {
    if (isShopPage) {
      setOpenShopDropdown(isShopPage);
    }
  }, [isShopPage]);

  // Nest secondary nav items within the baby shop primary nav item if available
  const navItems = useMemo(() => {
    const primaryNavItems = isGuest
      ? babyPreAuthPrimaryNavData
      : babyPostAuthPrimaryNavData(userContext);
    const babyShopNavItemIndex = primaryNavItems.findIndex(
      (item) => item.id === PrimaryNavIdentifier.SHOP
    );
    return secondaryNavData.length > 0
      ? [
          ...primaryNavItems.slice(0, babyShopNavItemIndex),
          { ...primaryNavItems[babyShopNavItemIndex], secondaryNavItems: secondaryNavData },
          ...primaryNavItems.slice(babyShopNavItemIndex + 1),
        ]
      : primaryNavItems;
  }, [isGuest, secondaryNavData, userContext]);

  const showLoginModal = () => {
    dispatch(
      showModal(
        MODAL_TYPE_AUTH,
        {
          authState: 'login',
          hideVendorLogin: true,
          loginCallback: () => {
            return window.location.assign('/onboard'); // redirects to manage registry if  already a baby user
          },
          signupLinkCallback: () => window.location.assign('/onboard'),
        },
        { size: 'sm', ariaLabel: 'Log in', v2Styles: true }
      )
    );
  };

  const logOut = () => {
    dispatch(signout())
      .then(() => {
        window.location.assign('/');
      })
      .catch(() => null);
  };

  return (
    <>
      <div
        className={cx(styles.overlay, { [styles.overlayOpen]: isVerticalNavOpen })}
        onClick={() => {
          openVerticalNav(false);
          openVerticalNavAccount(false);
        }}
        onKeyDown={() => null}
      />
      <div
        className={cx(styles.mobileVerticalNav, sharedStyles.verticalNav, {
          [sharedStyles.verticalNavOpen]: isVerticalNavOpen === true,
          [sharedStyles.verticalNavClose]: isVerticalNavOpen === false,
        })}
      >
        <div
          className={cx(sharedStyles.verticalNavInner, {
            [sharedStyles.verticalNavInnerOpen]: isVerticalNavOpen === true,
            [sharedStyles.verticalNavInnerClose]: isVerticalNavOpen === false,
          })}
        >
          <div className={sharedStyles.center}>
            <a aria-label="Zola Baby homepage" href="/">
              <ZolaBabyLogo />
            </a>
            <LinkV2 aria-label="Close menu" role="button" onClick={() => openVerticalNav(false)}>
              <XIcon height={20} title="" width={20} />
            </LinkV2>
          </div>
          <ButtonV3 className={styles.searchInput} onClick={toggleSearch} variant="transparent">
            <SearchIcon className={styles.searchInputIcon} height={20} title="" width={20} />
            Find products, brands, registries...
          </ButtonV3>
          {navItems.map((primaryNavItem, primaryNavItemIndex) => (
            <Fragment key={`primary-${primaryNavItemIndex}`}>
              <div className={styles.topLinkWrapper}>
                <LinkV2
                  className={cx(styles.topLink, sharedStyles.link)}
                  href={primaryNavItem.path}
                  noUnderline
                  onClick={(e) => {
                    if (primaryNavItem.id === PrimaryNavIdentifier.CHECKLIST) {
                      e.preventDefault();
                      window.dispatchEvent(new CustomEvent('OPEN_BABY_CHECKLIST_DRAWER'));
                    } else {
                      e.stopPropagation();
                    }
                  }}
                  sizes="regular"
                  subtle
                >
                  {primaryNavItem.title}
                </LinkV2>
                {'secondaryNavItems' in primaryNavItem && (
                  <ButtonV3
                    className={styles.dropdownButton}
                    onClick={() => setOpenShopDropdown(!openShopDropdown)}
                    variant="transparent"
                  >
                    <ChevronDownIcon
                      aria-hidden="true"
                      className={cx(styles.chevronIcon, {
                        [styles.open]: openShopDropdown,
                      })}
                      height={20}
                      showTitle={false}
                      width={20}
                    />
                  </ButtonV3>
                )}
              </div>
              {'secondaryNavItems' in primaryNavItem && (
                <div
                  className={cx(styles.dropdown, {
                    [styles.open]: openShopDropdown,
                  })}
                  id={`dropdown-${primaryNavItem.path}`}
                >
                  {primaryNavItem.secondaryNavItems.map(
                    (secondaryNavItem, secondaryNavItemIndex) => (
                      <LinkV2
                        className={cx(styles.dropdownLink, sharedStyles.link)}
                        href={secondaryNavItem.path}
                        key={`secondary-${secondaryNavItemIndex}`}
                        noUnderline
                        sizes="small"
                        subtle
                      >
                        {secondaryNavItem.title}
                      </LinkV2>
                    )
                  )}
                </div>
              )}
            </Fragment>
          ))}
          <div className={styles.bottomLinks}>
            {!isGuest && (
              <LinkV2
                className={cx(styles.bottomLink, sharedStyles.link, sharedStyles.center)}
                noUnderline
                onClick={() => openVerticalNavAccount(true)}
                role="button"
                sizes="small"
                subtle
              >
                Account settings
                <ChevronRightIcon aria-hidden="true" height={16} title="" width={16} />
              </LinkV2>
            )}
            <LinkV2
              className={cx(styles.bottomLink, sharedStyles.link)}
              href={APP_DOWNLOAD_HREF}
              noUnderline
              rel="noopener noreferrer"
              sizes="small"
              subtle
              target="_blank"
            >
              Baby Mobile App
            </LinkV2>
            <LinkV2
              className={cx(styles.bottomLink, sharedStyles.link)}
              href="/contact-us"
              noUnderline
              sizes="small"
              subtle
            >
              Contact us
            </LinkV2>
            <LinkV2
              className={cx(styles.bottomLink, sharedStyles.link)}
              href="/faq"
              noUnderline
              sizes="small"
              subtle
            >
              FAQs
            </LinkV2>
            <LinkV2
              className={cx(styles.bottomLink, sharedStyles.link)}
              href={getZolaHref(featureFlags.getEnvironment())}
              noUnderline
              sizes="small"
              subtle
              target="_blank"
            >
              Zola Weddings
              <ExternalLinkIcon
                className={styles.externalLinkIcon}
                showTitle={false}
                height={16}
                width={16}
              />
            </LinkV2>
          </div>
          <div className={styles.bottomButtons}>
            {isGuest ? (
              <>
                <ButtonV3 onClick={showLoginModal} size="large" variant="secondary">
                  Log in
                </ButtonV3>
                <ButtonV3 component="a" href="/onboard" size="large">
                  Get started
                </ButtonV3>
              </>
            ) : (
              <ButtonV3 onClick={logOut} size="large" variant="secondary">
                Log out
              </ButtonV3>
            )}
            <a
              aria-label="Download the Zola Baby app"
              className={styles.downloadButton}
              href={APP_DOWNLOAD_HREF}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="" src={DownloadButton} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
