import React, { Suspense, useState } from 'react';

import {
  PrimaryNavIdentifier,
  SecondaryNavItem,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import { MobilePrimaryNav } from '~/components/BabyNav/MobilePrimaryNav/MobilePrimaryNav';
import { MobileSecondaryNav } from '~/components/BabyNav/MobileSecondaryNav/MobileSecondaryNav';
import { MobileVerticalNav } from '~/components/BabyNav/MobileVerticalNav/MobileVerticalNav';
import { MobileVerticalNavAccount } from '~/components/BabyNav/MobileVerticalNavAccount/MobileVerticalNavAccount';
import UniversalSearch from '~/components/search/UniversalSearch';

import { toggleDocumentOverlayClass } from '~/util/overlayHelper';

import styles from './mobileNav.module.less';

type MobileNavProps = {
  hideContentOnScrollDown: boolean;
  primaryLinkId?: PrimaryNavIdentifier | '';
  secondaryLinkId: string;
  secondaryNavData: SecondaryNavItem[];
  userContext?: UserContext;
};

export const MobileNav = ({
  hideContentOnScrollDown,
  primaryLinkId,
  secondaryLinkId,
  secondaryNavData,
  userContext,
}: MobileNavProps): JSX.Element => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isVerticalNavOpen, setIsVerticalNavOpen] = useState<boolean | undefined>(undefined);
  const [isVerticalNavAccountOpen, setIsVerticalNavAccountOpen] = useState<boolean | undefined>(
    undefined
  );

  const openVerticalNavAccount = (open = true) => {
    setIsVerticalNavAccountOpen(open);
  };

  const openVerticalNav = (open = true) => {
    setIsVerticalNavOpen(open);
    toggleDocumentOverlayClass(open, 'baby-mobile-nav');

    // Open account layer of vertical nav when opening from account settings pages
    if (primaryLinkId === PrimaryNavIdentifier.ACCOUNT) {
      openVerticalNavAccount(open);
    }
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  return (
    <div className={styles.mobileNav}>
      <MobilePrimaryNav
        hideContentOnScrollDown={hideContentOnScrollDown}
        openVerticalNav={openVerticalNav}
      />
      {secondaryNavData.length > 0 && (
        <MobileSecondaryNav secondaryLinkId={secondaryLinkId} secondaryNavData={secondaryNavData} />
      )}
      <MobileVerticalNav
        isVerticalNavOpen={isVerticalNavOpen}
        openVerticalNav={openVerticalNav}
        openVerticalNavAccount={openVerticalNavAccount}
        primaryLinkId={primaryLinkId}
        secondaryNavData={secondaryNavData}
        toggleSearch={toggleSearch}
        userContext={userContext}
      />
      <MobileVerticalNavAccount
        isVerticalNavAccountOpen={isVerticalNavAccountOpen}
        openVerticalNav={openVerticalNav}
        openVerticalNavAccount={openVerticalNavAccount}
        userContext={userContext}
      />
      {isSearchOpen ? (
        <Suspense fallback={<div>Loading search result</div>}>
          <div className={styles.universalSearchContainer}>
            <UniversalSearch activeLinkId={primaryLinkId} fixedWidth onCloseSearch={toggleSearch} />
          </div>
        </Suspense>
      ) : null}
    </div>
  );
};
